import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image";

const FishAboutImage = () => {
    const data = useStaticQuery(graphql`{
        placeholderImage: file(relativePath: {eq: "nature.jpg"}) {
            name
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }`)

    if (!data?.placeholderImage?.childImageSharp?.gatsbyImageData) {
        return <div>Picture not found</div>
    }

    return (
        <GatsbyImage
            image={data.placeholderImage.childImageSharp.gatsbyImageData}
            alt={data.placeholderImage.name}
            style={{height: "100%"}}/>
    );
}

export default FishAboutImage
