import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image";

const HuntBannerImage = () => {
    const data = useStaticQuery(graphql`{
        placeholderImage: file(relativePath: {eq: "family.jpg"}) {
            name
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }`)

    if (!data?.placeholderImage?.childImageSharp?.gatsbyImageData) {
        return <div>Picture not found</div>
    }

    return (<GatsbyImage
        alt={data.placeholderImage.name}
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
    />);
}

export default HuntBannerImage
