import React, { useState } from "react"
import { SECTION_ONE } from "../data"

const defaultState = {
	contentId: SECTION_ONE.id,
	prevContentId: SECTION_ONE.id,
	switchContent: () => {},
}

const ContentContext = React.createContext(defaultState)

const ContentProvider = ({ children }) => {
	const [contentId, setContentId] = useState(SECTION_ONE.id)
	const [prevContentId, setPrevContentId] = useState(SECTION_ONE.id)

	const switchContent = (id, prevId) => {
		setContentId(id)
		setPrevContentId(prevId)
	}

	return (
		<ContentContext.Provider
			value={{
				contentId,
				prevContentId,
				switchContent,
			}}
		>
			{children}
		</ContentContext.Provider>
	)
}

export default ContentContext

export { ContentProvider }
