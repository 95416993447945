export const colors = {
	brown: {
		primary: "#84573b",
		secondary: "#B76D3F",
		light: "#FFC7A3",
	},
	blue: {
		primary: "#2d4457",
		light: "#4CABBC",
	},
	green: {
		primary: "#3d6756",
		secondary: "#4B9373",
		light: "#A3EFD7",
	},
	white: "#fff",
	text: "#000",
	black: "#000",
	white50: "#ffffff88",
}

export const borderRadius = {
	xs: "6px",
	sm: "8px",
	md: "12px",
	lg: "15px",
}

export const shadows = {
	black: "0 0 15px rgba(0, 0, 0, 0.2)",
	white: "0 0 15px rgba(255, 255, 255, 0.5)",
	white2: "0 5px 15px rgba(255, 255, 255, 0.5)",
	white3: "0 10px 15px rgba(255, 255, 255, 0.5)",
}

export const topBarActiveHeight = 70
export const topBarInActiveHeight = 50

export const huntGallerySliderHeight = 700
export const fishGallerySliderHeight = 700
export const huntGroundsGallerySliderHeight = 700
export const skiGallerySliderHeight = 700

export const gallerySliderSettings = {
	dots: true,
	arrows: true,
	adaptiveHeight: true,
}