import HuntSectionImage from "./components/images/HuntSectionImage"
import { colors } from "./styles/global.variables"
import FishSectionImage from "./components/images/FishSectionImage"
import SkiSectionImage from "./components/images/SkiSectionImage"
import HuntMap from "./images/svg/hunt-map.svg"
import FishMap from "./images/svg/fish-map.svg"
import SkiMap from "./images/svg/ski-map.svg"
import HuntIcon from "./images/svg/bullet.svg"
import HuntIconDark from "./images/svg/bullet-dark.svg"
import FishIcon from "./images/hook.svg"
import FishIconDark from "./images/svg/hook-dark.svg"
import SkiIcon from "./images/goggles.svg"
import SkiIconDark from "./images/svg/goggles-dark.svg"
import React from "react"
import HuntAboutImage from "./components/images/HuntAboutImage"
import WoodImage from "./components/images/WoodImage"
import HuntFeaturesImage from "./components/images/HuntFeaturesImage"
import HuntBannerImage from "./components/images/HuntBannerImage"
import HuntSliderFirstImage from "./components/images/HuntSliderFirstImage"
import HuntSliderSecondImage from "./components/images/HuntSliderSecondImage"
import FishAboutImage from "./components/images/FishAboutImage"
import SeaImage from "./components/images/SeaImage"
import FishFeaturesImage from "./components/images/FishFeaturesImage"
import FishBannerImage from "./components/images/FishBannerImage"
import SkiAboutImage from "./components/images/SkiAboutImage"
import IceMountainsImage from "./components/images/IceMountainsImage"
import SkiExploreImage from "./components/images/SkiExploreImage"
import HikingImage from "./components/images/HikingImage"

export const SECTION_ONE = {
	id: "hunt",
	title: "Beaver Creek Ranch Hunt",
	route: "/",
	color: colors.brown,
	icon: HuntIcon,
	iconDark: HuntIconDark,
}

export const SECTION_TWO = {
	id: "fish",
	title: "Port O’Connor Fish",
	route: "/port-o-connor-fish/",
	color: colors.blue,
	icon: FishIcon,
	iconDark: FishIconDark,
	link: "https://www.pocrentals.com/Hotels/details.aspx?hotelID=129",
}

export const SECTION_THREE = {
	id: "ski",
	title: "Big Sky Adventure",
	route: "/big-sky-adventure/",
	color: colors.green,
	icon: SkiIcon,
	iconDark: SkiIconDark,
	link: "https://www.bookbigsky.com/property/cowboy-heaven-luxury-suite-3b",
}

export const huntDropdownLinks = [
	{ id: 0, title: "Home", route: "#main" },
	{ id: 1, title: "About", route: "#about" },
	{ id: 2, title: "Gallery", route: "#huntGallery" },
	{ id: 3, title: "Amenities", route: "#amenities" },
	{ id: 4, title: "Pricing", route: "#pricing" },
	{ id: 5, title: "Contact Us", route: "#contact" },
]

export const fishDropdownLinks = [
	{ id: 0, title: "Home", route: "#main" },
	{ id: 1, title: "About", route: "#about" },
	{ id: 2, title: "Gallery", route: "#fishGallery" },
	{ id: 3, title: "Amenities", route: "#amenities" },
	{ id: 4, title: "Contact Us", route: "#contact" },
]

export const skiDropdownLinks = [
	{ id: 0, title: "Home", route: "#main" },
	{ id: 1, title: "About", route: "#skiAbout" },
	{ id: 2, title: "Gallery", route: "#skiGallery" },
	{ id: 3, title: "Explore", route: "#skiExplore" },
	{ id: 4, title: "Contact Us", route: "#contact" },
]

export const heroSections = [
	{
		id: SECTION_ONE.id,
		title: SECTION_ONE.title,
		route: SECTION_ONE.route,
		description:
			"A unique hunting experience in central Texas. Densely forested oak woodlands interspersed with natural rolling meadows & lush pastures await - framing the perfect background for a ‘Hunt of a Lifetime’",
		image: <HuntSectionImage />,
		map: HuntMap,
		icon: SECTION_ONE.icon,
		color: SECTION_ONE.color,
		dropdownLinks: huntDropdownLinks,
	},
	{
		id: SECTION_TWO.id,
		title: SECTION_TWO.title,
		route: SECTION_TWO.route,
		description:
			"From tidal flats to bays to offshore - your adventure starts with your choice of gamefish! Full Crew Accommodations",
		image: <FishSectionImage />,
		map: FishMap,
		icon: SECTION_TWO.icon,
		color: SECTION_TWO.color,
		dropdownLinks: fishDropdownLinks,
	},
	{
		id: SECTION_THREE.id,
		title: SECTION_THREE.title,
		route: SECTION_THREE.route,
		description:
			"Picturesque mountains and cold more your style? Big Sky Adventure won’t disappoint. Ski In / Ski Out Luxury",
		image: <SkiSectionImage />,
		map: SkiMap,
		icon: SECTION_THREE.icon,
		color: SECTION_THREE.color,
		dropdownLinks: skiDropdownLinks,
	},
]

export const aboutSections = [
	{
		id: SECTION_ONE.id,
		elementId: "about",
		title: SECTION_ONE.title,
		subtitle: "World Class Trophy Hunts",
		text:
			"Beaver Creek Ranch in Milano, TX, provides you with a “Hunt of a Lifetime”. From our rustic accommodations for each hunter, to the large, clubhouse and mess hall, to the firepit and relaxation area to the world class animals for the taking, we take pride in every step of your experience and leave you wanting to return again and again.",
		buttonText: "Amenities",
		icon: SECTION_ONE.icon,
		image: <HuntAboutImage />,
		bgImage: <WoodImage />,
		color: SECTION_ONE.color,
	},
	{
		id: SECTION_TWO.id,
		elementId: "about",
		title: "Flats. Bay. Offshore.",
		subtitle: "Choose wisely!",
		text:
			"Beaver Creek | Fish in Port O’ Connor offers an unparalleled experience allowing you options to any number of fish species.  From the flats and marshes for redfish, to bay fishing for record speckled trout to offshore for tuna and the like, we offer it all.",
		buttonText: "Features",
		icon: SECTION_TWO.icon,
		image: <FishAboutImage />,
		bgImage: <SeaImage />,
		color: SECTION_TWO.color,
	},
	{
		id: SECTION_THREE.id,
		title: "Ski Big Sky",
		elementId: "skiAbout",
		subtitle: "The best Montana has to offer!",
		text:
			"This 3 bedroom ski in/ski out villa has access to all of the slopes of Big Sky. From the beginner to expert, and skier to snowboarder, everyone will find the perfect run! Just 5 miles from the restaurants and stores of downtown Big Sky, Big Sky Adventure is exactly what you and your family need for a family skiing fun.",
		image: <SkiAboutImage />,
		bgImage: <IceMountainsImage />,
		color: SECTION_THREE.color,
	},
]

export const featuresSections = [
	{
		id: SECTION_ONE.id,
		color: SECTION_ONE.color,
		bgImage: <HuntFeaturesImage />,
		imageText: "World Class Hunts",
		icon: SECTION_ONE.icon,
		featuresArray: [
			{
				id: 1,
				title: "Amenities",
				subFeatures: [
					{ id: 1, title: "1 on 1 Guided Hunts" },
					{ id: 2, title: "Ranch Transportation" },
					{
						id: 3,
						title:
							"Complete Trophy Care (Field dressing, caping and quartering of meat for transportation)",
					},
				],
			},
			{
				id: 2,
				title: "On Property",
				subFeatures: [
					{ id: 1, title: "Gameroom/Clubhouse" },
					{ id: 2, title: "TV/Card Table/Wet Bar" },
					{ id: 3, title: "Fire Pit/Cigar/Social Area" },
					{ id: 4, title: "Vehicle Parking" },
					{ id: 5, title: "Archery Target Practicing" },
					{ id: 6, title: "Clay Shooting" },
					{ id: 7, title: "Bass/Catfish/Crappie Fishing" },
				],
			},
			{
				id: 3,
				title: "Available (Separate Fees)",
				subFeatures: [
					{ id: 1, title: "Lodging" },
					{ id: 2, title: "Animal Processing/Shipping" },
					{ id: 3, title: "Photo Safari Ride" },
					{ id: 4, title: "Transportation to Austin/College Station/Houston" },
					{ id: 5, title: "Shopping in Above Listed Cities" },
					{ id: 6, title: "Massage/Spa" },
					{ id: 7, title: "Authentic Mexican Meal" },
				],
			},
		],
	},
	{
		id: SECTION_TWO.id,
		color: SECTION_TWO.color,
		bgImage: <FishFeaturesImage />,
		imageText: "Redefined Luxury",
		icon: SECTION_TWO.icon,
		featuresArray: [
			{
				id: 1,
				title: "Offering",
				subFeatures: [
					{ id: 1, title: "Over 6,100 sq ft" },
					{ id: 2, title: "8 bedrooms, 8 bathrooms" },
					{ id: 3, title: "Massive, 3-level deck spaces" },
				],
			},
			{
				id: 2,
				title: "Fishing",
				subFeatures: [
					{ id: 1, title: "Boat stall included with rental" },
					{ id: 2, title: "3 guides available" },
					{ id: 4, title: "5’ flats boat" },
					{ id: 6, title: "7’ Kenner bay" },
					{ id: 8, title: "Triple engine offshore boat" },
					{ id: 9, title: "Flounder gigging" },
					{ id: 10, title: "Fish cleaning station" },
					{ id: 11, title: "Freshwater boat washdown" },
				],
			},
		],
	},
]

export const huntPrices = [
	{
		id: 1,
		categoryTitle: "Whitetail Deer Hunts",
		prices: [
			{ id: 1, title: 'Up to 159" Class', price: "$5,500" },
			{ id: 2, title: '160" - 179" Class', price: "$7,500" },
			{ id: 3, title: '180" - 199" Class', price: "$10,000" },
			{ id: 4, title: '200" - 224" Class', price: "$15,000" },
			// { id: 5, title: '225" - 249" Class', price: "$30,000" },
			{ id: 6, title: '225" Class & Up', price: "Upon Request" },
			{ id: 7, title: "Doe", price: "$500" },
		],
	},
	{
		id: 2,
		categoryTitle: "Exotic Animals",
		prices: [
			{ id: 1, title: "Addax Bull", price: "$10,000" },
			{ id: 2, title: "Aoudad", price: "$4,500" },
			{ id: 3, title: "Axis Buck", price: "$6,000" },
			{ id: 4, title: "Axis Doe", price: "$1,000" },
			{ id: 5, title: "Bison", price: "$7,250" },
			{ id: 6, title: "Blackbuck", price: "$5,000" },
			{ id: 7, title: "Blackbuck Doe", price: "$750" },
			{ id: 8, title: "Black Hawaiian Sheep", price: "$2,000" },
			{ id: 9, title: "Blesbok", price: "$8,500" },
			{ id: 10, title: "Corsican Ram", price: "$2,000" },
			{ id: 11, title: "Eland Bull", price: "NOT AVAILABLE" },
			{ id: 12, title: "Eland Cow", price: "NOT AVAILABLE" },
			{ id: 13, title: 'Elk Bull 359" or less', price: "$7,250" },
			{ id: 14, title: 'Elk Bull 360" - 374"', price: "$8,250" },
			{ id: 15, title: 'Elk Bull 375" - 399"', price: "$ 9,500 - 12,000" },
			{ id: 16, title: 'Elk Bull 400" Plus', price: "Upon Request" },
			{ id: 17, title: "Elk Cow", price: "$2,750" },
			{ id: 18, title: "Fallow Deer Buck", price: "$ 5,000 - $7,500" },
			{ id: 19, title: "Fallow Deer Doe", price: "$1,000" },
			{ id: 20, title: "Gemsbok Bull", price: "$10,000" },
			{ id: 21, title: "Impala", price: "$7,500" },
			{ id: 23, title: "Nilgai", price: "$7,500" },
			{ id: 24, title: "Pierre-David Bull", price: "$10,000" },
			{ id: 25, title: "Pierre-David Cow", price: "$2,500" },
			{ id: 26, title: "Red Lechwe", price: "$10,000" },
			{ id: 27, title: "Red Stag Bull", price: "Upon Request" },
			{ id: 28, title: "Red Stag Cow", price: "Upon Request" },
			{ id: 29, title: "Scimitar Horned Oryx", price: "$7,500" },
			{ id: 31, title: "Texas Dall Sheep", price: "$2,000" },
			{ id: 32, title: "Water Buffalo", price: "$4,750" },
			{ id: 33, title: "Wild Hogs with Exotic Hunt", price: "$550" },
			{ id: 34, title: "Wildebeest Bull", price: "$7,750" },
			{ id: 35, title: "Wildebeest Cow", price: "$5,000" },
			{ id: 36, title: "Zebra Stud", price: "NOT AVAILABLE" },
			{ id: 37, title: "Zebra Mare", price: "NOT AVAILABLE" },
		],
	},
]

export const bannerSections = [
	{
		id: SECTION_ONE.id,
		color: SECTION_ONE.color,
		title: "Family friendly from the time they can shoot!",
		image: <HuntBannerImage />,
	},
	{
		id: SECTION_TWO.id,
		color: SECTION_TWO.color,
		image: <FishBannerImage />,
	},
]

export const huntSlider = [
	{
		id: 1,
		text:
			"The mess hall and clubhouse, individually rented cabins, covered commons and firepit/relaxation area",
		image: <HuntSliderFirstImage />,
		imageText: "Rustic. Exceptional. Relaxing.",
	},
	{
		id: 2,
		text:
			"Or if individual solace is more your style, we have several areas throughout the ranch for some quiet reflection time/relaxation area",
		image: <HuntSliderSecondImage />,
		imageText: "Rustic. Exceptional. Relaxing.",
	},
]

export const skiSecondSection = {
	id: SECTION_THREE.id,
	title: "Explore Big Sky",
	subtitle: "ADVENTURE AWAITS!",
	elementId: "skiExplore",
	text:
		"When not tackling the mountain, Big Sky Adventure offers a host of other activities nearby",
	list: [
		{ id: 1, title: "Hiking" },
		{ id: 2, title: "Sightseeing" },
		{ id: 3, title: "Fishing" },
		{ id: 4, title: "Hunting" },
		{ id: 5, title: "Shopping" },
		{ id: 6, title: "Fine Dining" },
		{ id: 7, title: "Horseback Riding" },
		{ id: 8, title: "Historic Tours" },
	],
	image: <HikingImage />,
	imageFooterText:
		"Image via Montana Office of Tourism and Business Development",
	imageFooterLink: "https://www.visitmt.com/",
	bgImage: <SkiExploreImage />,
	color: SECTION_THREE.color,
}

export const beaverCreekAddress = {
	lat: 30.750072,
	lng: -96.788426,
}

export const companyLinks = [
	{
		id: SECTION_TWO.id,
		title: SECTION_TWO.title,
		icon: SECTION_TWO.icon,
		color: SECTION_TWO.color,
		text: "Ready to Reserve / Consider Dates?",
		link: SECTION_TWO.link,
	},
	{
		id: SECTION_THREE.id,
		title: SECTION_THREE.title,
		icon: SECTION_THREE.icon,
		color: SECTION_THREE.color,
		text: "Ready to Reserve or Consider Dates?",
		link: SECTION_THREE.link,
	},
]

export const beaverCreekPropertiesEmail = "info@beavercreekranch.us"
